/*! Basic Scripts 17.08.2022 */

/*----------------------------------------
  Shrinking Header
----------------------------------------*/
$(document).on("scroll", function(){
  if ($(document).scrollTop() > 30){
    $("header").addClass("shrink");
  } else {
    $("header").removeClass("shrink");
  }
});


/*----------------------------------------
  Top-Link
----------------------------------------*/
$(document).ready(function() {
  $('div.toplink').hide();
  $(window).scroll(function(){
    var value = 200;
    var scrolling = $(window).scrollTop();
    if (scrolling > value) {
      $('div.toplink').fadeIn();
    } else {
      $('div.toplink').fadeOut();
    }
  });
  $('div.toplink').click(function(){
    $('html, body').animate({scrollTop:'0px'}, 800);
    return false;
  });
});


/*----------------------------------------
  Navbar Mobile Toggler
----------------------------------------*/
$(document).ready(function() {
  $('.navbar-toggler').click(function() {
    $(".navbar-toggler").toggleClass("navbar-toggler--active");
    $(".nav--mobile").toggleClass("nav--mobile--active");
  });

  $('.nav--mobile li a').click(function() {
    $(".navbar-toggler").toggleClass("navbar-toggler--active");
    $(".nav--mobile").toggleClass("nav--mobile--active");
  });
});
